<script setup>
import LayoutContainer from "../layout/LayoutContainer.vue"
import {OneButton} from "@jirizavadil/framework.one/ui"
import sink from "@/sink/index.js"

defineProps({
  content: Object,
})

const isDarkMode = window.matchMedia("(prefers-color-scheme: dark)").matches

function onClickCTA(cta) {
  window.open(cta.href, "_blank")
  sink.event("PartnerCTAClick")
}

function onClickPartner(partner) {
  sink.event("PartnerClick", {
    url: partner.url,
  })
}
</script>

<template>
  <LayoutContainer>
    <!-- title -->
    <div class="">
      <h3 class="whitespace-pre-wrap text-left text-lg font-bold text-neutral-400 md:text-xl">
        {{ content.title }}
      </h3>
    </div>

    <!-- items -->
    <div class="mt-4 grid grid-cols-2 gap-2 md:mt-6 md:grid-cols-4 md:gap-4">
      <a
        class="flex h-16 rounded-xl bg-neutral-50 flex-center md:h-20 dark:bg-neutral-900"
        :href="item.url"
        target="_blank"
        v-for="item in content.list"
        :key="item.id"
        @click="onClickPartner(item)"
      >
        <img
          class="opacity-100"
          :class="item.class"
          :src="isDarkMode ? item.logoOnDark : item.logo"
        />
      </a>
    </div>

    <!-- cta -->
    <!--<div class="mt-4 flex opacity-50 flex-center">
      <OneButton
        kind="ghost"
        variant="secondary"
        :size="10"
        :label="content.cta.label"
        @action="onClickCTA(content.cta)"
      />
    </div>-->
  </LayoutContainer>
</template>
