import MainView from "../views/MainView.vue"

export const routes = [
  {
    path: "/",
    name: "MainView",
    component: MainView,
    meta: {
      bodyFixed: false,
      requiresAuth: false,
    },
  },
  {
    path: "/chat",
    name: "ChatView",
    component: () => import("../views/ChatView.vue"),
    children: [
      {
        path: "no-funds",
        name: "ChatNoFundsView",
        component: () => import("../views/ChatNoFundsView.vue"),
      },
      {
        path: "thank-you",
        name: "ChatThankYouView",
        component: () => import("../views/ChatThankYouView.vue"),
      },
    ],
  },
  {
    path: "/terms",
    name: "TermsView",
    component: () => import("../views/TermsView.vue"),
  },
  {path: "", redirect: {name: "MainView"}},
  {path: "/:pathMatch(.*)*", redirect: {name: "MainView"}},
]
