<script setup>
import LayoutContainer from "../layout/LayoutContainer.vue"
import {OneButton} from "@jirizavadil/framework.one/ui"
import sink from "@/sink/index.js"

const props = defineProps({
  content: Object,
})

const isDarkMode = window.matchMedia("(prefers-color-scheme: dark)").matches

function onClickCTA(href) {
  window.open(href, "_blank")
  sink.event("SpecialOfferCTAClick")
}
</script>

<template>
  <LayoutContainer>
    <!-- title -->
    <!-- <div class="mt-20">
      <h3 class="text-4xl font-bold">{{ content.title }}</h3>
    </div> -->

    <!-- items -->
    <div class="mt-0">
      <div
        class="flex flex-col justify-between rounded-3xl bg-neutral-950 p-6 text-white md:p-12 dark:bg-neutral-100 dark:text-neutral-900"
      >
        <div>
          <!-- title -->
          <div
            class="mt-0 flex flex-col-reverse items-start justify-between gap-4 md:flex-row md:gap-0"
          >
            <h4
              class="mt-0 whitespace-pre-wrap text-2xl font-semibold uppercase leading-7 md:text-3xl"
            >
              {{ content.title }}
            </h4>
            <span
              class="shrink-0 rounded-full bg-success-500 px-4 py-1 text-sm font-bold text-neutral-900 md:text-lg"
              v-if="content.highlighted"
              >{{ content.highlightedLabel }}</span
            >
          </div>

          <!-- description -->
          <div class="mt-2 flex flex-col gap-1 md:mt-2">
            <p class="text-neutral-400 dark:text-neutral-500">{{ content.description }}</p>
          </div>

          <!-- caption -->
          <div class="mt-4 flex flex-col gap-1 md:mt-4">
            <p class="whitespace-pre-wrap text-xs text-neutral-400">{{ content.caption }}</p>
          </div>
        </div>
        <div>
          <!-- cta -->
          <OneButton
            class="mt-6"
            :label="content.cta.label"
            :variant="isDarkMode ? 'black' : 'white'"
            iconEnd="ArrowRight"
            :iconStrokeWidth="3"
            :size="12"
            iconAnimate
            @action="onClickCTA(content.cta.href)"
          />
        </div>
      </div>
    </div>
  </LayoutContainer>
</template>
