<script setup>
import LayoutContainer from "../layout/LayoutContainer.vue"
import {useLandingStore} from "../../stores/landing"
import {storeToRefs} from "pinia"

defineProps({
  content: Object,
})

const landingStore = useLandingStore()
const {landing} = storeToRefs(landingStore)
</script>

<template>
  <div class="">
    <LayoutContainer>
      <!--<div
        class="flex w-full flex-col justify-between gap-4 rounded-xl border border-neutral-200 p-6 text-left md:flex-row dark:border-neutral-800"
      >-->
      <div
        class="grid w-full grid-cols-2 gap-4 rounded-xl border border-neutral-200 p-6 text-left dark:border-neutral-800"
      >
        <!-- sources -->
        <div class="">
          <!--<span class="block text-4xl">{{ landing?.stats?.sources }}</span>-->
          <span class="block text-4xl">160+</span>
          <span class="text-neutral-400">{{ content.sourcesLabel }}</span>
        </div>

        <!-- questions -->
        <div class="flex flex-row gap-8">
          <div
            class="h-px min-h-full border-t border-neutral-200 md:h-auto md:w-px md:border-l dark:border-neutral-800"
          ></div>
          <div class="xborder-l xpl-6">
            <!--<span class="block text-4xl">{{ landing?.stats?.questions }}</span>-->
            <span class="block text-4xl">1000+</span>
            <span class="text-neutral-400">{{ content.questionsLabel }}</span>
          </div>
        </div>

        <!--<div
          class="h-px min-h-full border-t border-neutral-200 md:h-auto md:w-px md:border-l dark:border-neutral-800"
        ></div>-->

        <!-- contributed -->
        <!--<div class="xborder-l xpl-6">
          <span class="block text-4xl">{{ landing?.stats?.contributedAED }}</span>
          <span class="text-neutral-400">{{ content.contributedLabel }}</span>
        </div>-->
      </div>
    </LayoutContainer>
  </div>
</template>
