import {createRouter, createWebHistory} from "vue-router"
import {routes} from "./routes"
// import {useUserStore} from "../stores/user"
// import {useAppStore} from "../stores/app"
import sink from "@/sink/index.js"

const router = createRouter({
  history: createWebHistory(),
  routes,
  scrollBehavior() {
    return {top: 0}
  },
})

router.afterEach((to, from) => {
  // log event
  sink.event("screen_view", {
    firebase_screen: to.name.replace("View", ""),
  })
})

export {router}
