<script setup>
import {ref, onMounted} from "vue"

const props = defineProps({
  bubbles: Array,
})

const speed = 2000
const bubblesItems = ref([props.bubbles[0]])

function feedStream() {
  // addRandomItemToFeed()
  setInterval(() => {
    addNextItemToFeed()
  }, speed)
}

const lastItemIndex = ref(0)

function addNextItemToFeed() {
  // console.log("addNextItemToFeed()")
  const newItemIndex = lastItemIndex.value + 1
  const items = props.bubbles
  const item = items[newItemIndex]
  // console.log({newItemIndex, item})
  if (item) {
    // bubblesItems.value.unshift(item)
    bubblesItems.value.push(item)
  }
  if (newItemIndex + 1 == items.length) {
    // bubblesItems.value.splice(1)
    lastItemIndex.value = -1
  } else {
    lastItemIndex.value++
  }
}

onMounted(() => {
  feedStream()
})
</script>

<template>
  <div class="relative flex h-[500px] flex-col justify-end gap-6 overflow-hidden">
    <!-- gradient -->
    <div
      class="absolute left-0 right-0 top-0 z-10 h-40 w-full bg-gradient-to-t from-transparent to-white dark:to-neutral-950"
    ></div>

    <!-- bubbles -->
    <div
      class="fade-in inline-flex flex-col items-end gap-1"
      v-for="item in bubblesItems"
      :key="item.id"
    >
      <div
        class="rounded-t-3xl rounded-bl-3xl rounded-br-lg bg-neutral-100 p-4 dark:bg-neutral-900"
      >
        <p class="max-w-[350px] text-neutral-400">{{ item.question }}</p>
      </div>
      <span class="font-bold uppercase text-neutral-400 dark:text-neutral-600">{{ item.topic }}</span>
    </div>

    <!-- gradient -->
    <!--<div
      class="absolute bottom-0 left-0 right-0 z-10 h-40 w-full bg-gradient-to-t from-white to-transparent"
    ></div>-->
  </div>
</template>

<style>
.fade-in {
  animation: fadeIn 1s ease-out forwards;
}

@keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
</style>
