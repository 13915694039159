<script setup>
import content from "./content.js"

const contentLang = "en"
</script>

<template>
  <div class="">
    <RouterView :content="content.content[contentLang]" />
  </div>
</template>
