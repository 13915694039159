<script setup>
import LayoutContainer from "../layout/LayoutContainer.vue"
import {OIcon} from "@jirizavadil/framework.one/ui"
import sink from "@/sink/index.js"

defineProps({
  content: Object,
})

function onClickLink(link) {
  sink.event("SocialClick", {
    social: link.title,
  })
}

function onClickTerms() {
  sink.event("TermsClick")
}
</script>

<template>
  <div class="bg-neutral-50 py-8 dark:bg-neutral-900">
    <LayoutContainer class="grid w-full grid-cols-3">
      <div class="flex flex-row gap-5">
        <!-- company -->
        <span class="text-neutral-300 dark:text-neutral-700">{{ content.company }}</span>

        <!-- terms -->
        <RouterLink
          :to="{name: 'TermsView'}"
          class="text-neutral-300 dark:text-neutral-700"
          @click="onClickTerms"
          >{{ content.termsLabel }}</RouterLink
        >
      </div>

      <!-- author -->
      <div class="text-center">
        <span class="dark:text-neutral-500">{{ content.author }}</span>
      </div>

      <!-- links -->
      <div class="">
        <div class="flex flex-col items-end justify-center gap-5 pl-4 md:flex-row md:items-center">
          <a
            class="transition-all duration-300 ease-in-out hover:text-neutral-500"
            :href="link.url"
            target="_blank"
            v-for="link in content.links"
            :key="link.id"
            @click="onClickLink(link)"
          >
            <OIcon class="text-neutral-900" :lucide="link.icon" v-if="link.icon" />
            <span class="text-neutral-300" v-else>{{ link.title }}</span>
          </a>
        </div>
      </div>
    </LayoutContainer>
  </div>
</template>
