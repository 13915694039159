<script setup>
import LayoutContainer from "../layout/LayoutContainer.vue"
import {OneButton} from "@jirizavadil/framework.one/ui"
import sink from "@/sink/index.js"
import Bubbles from "../hero/Bubbles.vue"
import Header from "../Header.vue"

defineProps({
  content: Object,
  contentGlobal: Object,
})

function onClickCTA() {
  sink.event("ChatStartClick", {view: "landing", section: "hero"})
  window.open("https://client.askbot.ae/askfounder/chat", "_blank")
}
</script>

<template>
  <LayoutContainer>
    <Header :content :contentGlobal view="landing" />

    <div class="flex flex-col justify-between md:flex-row">
      <div class="">
        <!-- title -->
        <div class="mt-20 max-w-4xl">
          <h2
            class="text-3xl leading-10 md:text-5xl md:leading-[3.75rem] dark:text-white"
            v-html="content.title"
          ></h2>
          <p
            class="mt-3 max-w-2xl text-lg text-neutral-500 md:mt-6 md:text-xl"
            v-html="content.subtitle"
          ></p>
        </div>

        <!-- cta -->
        <div class="mt-12">
          <OneButton
            :label="content.cta"
            :size="14"
            iconEnd="ArrowRight"
            :iconStrokeWidth="3"
            iconAnimate
            @action="onClickCTA()"
          />

          <!-- <span class="mt-2 inline-block pl-7"
        ><span
          ><span class="text-neutral-400">{{ content.cta2or }}</span
          >&nbsp;<a
            class="hover:underline"
            :href="content.cta2.href"
            target="_blank"
            @action="onClickCTA2()"
          >
            {{ content.cta2.label }}</a
          ></span
        ></span
      > -->
        </div>
      </div>

      <!-- bubbles-->
      <div class="pt-10">
        <Bubbles :bubbles="content.bubbles" />
      </div>
    </div>
  </LayoutContainer>
</template>
