<script setup>
import LayoutContainer from "../layout/LayoutContainer.vue"
import {OneButton} from "@jirizavadil/framework.one/ui"
import sink from "@/sink/index.js"

defineProps({
  content: Object,
})

function onClickCTA(item) {
  window.open(item.cta.href, "_blank")
  // first letter uppercase
  const name = item.id.charAt(0).toUpperCase() + item.id.slice(1)
  sink.event(`Team${name}WebClick`)
}

</script>

<template>
  <LayoutContainer>
    <!-- title -->
    <div class="mt-20">
      <h3 class="text-4xl font-bold">{{ content.title }}</h3>
    </div>

    <!-- items -->
    <div class="mt-8 grid grid-cols-1 gap-6 md:grid-cols-2">
      <div
        class="flex flex-col justify-between rounded-3xl border border-neutral-200 p-6 pb-3 dark:border-neutral-800"
        v-for="item in content.list"
        :key="item.id"
      >
        <div>
          <!-- image -->
          <img :src="item.image" class="mb-4 h-20 w-20 rounded-full" />

          <!-- title -->
          <div class="flex flex-row items-start justify-between">
            <h4 class="mt-0 text-lg font-semibold md:text-xl">{{ item.name }}</h4>
          </div>

          <!-- description -->
          <div class="mt-0 flex flex-col gap-1">
            <p class="text-neutral-400">{{ item.description }}</p>
          </div>
        </div>
        <div class="mt-6 border-t border-neutral-200 dark:border-neutral-800">
          <!-- cta -->
          <OneButton
            class="mt-2"
            :label="item.cta.label"
            kind="plain"
            iconEnd="ArrowUpRight"
            :iconStrokeWidth="3"
            iconAnimate
            @action="onClickCTA(item)"
          />
        </div>
      </div>
    </div>
  </LayoutContainer>
</template>
