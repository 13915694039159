<script setup>
import LayoutContainer from "../layout/LayoutContainer.vue"
import {OneButton} from "@jirizavadil/framework.one/ui"
import sink from "@/sink/index.js"

const props = defineProps({
  content: Object,
  contentGlobal: Object,
})

const customContributionOption = props.contentGlobal.contributionOptions.find(
  (option) => option.custom
)

const production =
  import.meta.env.VITE_ENVIRONMENT === "production" && !location.href.includes("--preview")

function onClickCTA(item) {
  if (item.cta.href === "contribute") {
    const url = production
      ? customContributionOption.paymentUrl
      : customContributionOption.paymentUrlTest

    window.open(url, "_blank")

    sink.event("ContributeKeepFreeCTAClick")
  } else {
    window.location.href = `${item.cta.href}`
    sink.event("ContributeKnowledgeCTAClick")
  }
}
</script>

<template>
  <LayoutContainer>
    <!-- title -->
    <div class="mt-20">
      <h3 class="text-4xl font-bold">{{ content.title }}</h3>
    </div>

    <!-- items -->
    <div class="mt-8 grid grid-cols-1 gap-6 md:grid-cols-2">
      <div
        class="flex flex-col justify-between rounded-3xl bg-neutral-100 p-6 text-neutral-900 dark:bg-neutral-900 dark:text-neutral-100"
        v-for="item in content.list"
        :key="item.id"
      >
        <div>
          <!-- title -->
          <div class="flex flex-row items-start justify-between">
            <h4 class="mt-0 text-lg font-semibold uppercase md:text-xl">{{ item.title }}</h4>
            <span
              class="rounded-full bg-success-500 px-4 py-1 text-sm text-neutral-900 md:text-lg"
              v-if="item.highlighted"
              >{{ item.highlightedLabel }}</span
            >
          </div>

          <!-- description -->
          <div class="mt-2 flex flex-col gap-1 md:mt-2">
            <span class="text-neutral-400">{{ item.description }}</span>
          </div>
        </div>
        <div>
          <!-- cta -->
          <OneButton
            class="mt-6"
            :label="item.cta.label"
            :variant="item.highlighted ? 'white' : 'primary'"
            iconEnd="ArrowRight"
            :iconStrokeWidth="3"
            iconAnimate
            @action="onClickCTA(item)"
          />
        </div>
      </div>
    </div>
  </LayoutContainer>
</template>
