import {getApp, getApps, initializeApp} from "firebase/app"
import {initializeFirestore, connectFirestoreEmulator} from "firebase/firestore"
import {getFunctions, connectFunctionsEmulator} from "firebase/functions"
import {getStorage, connectStorageEmulator} from "firebase/storage"
import {getAuth, connectAuthEmulator} from "firebase/auth"
import {getAnalytics, isSupported as isSupportedAnalytics} from "firebase/analytics"
// import * as firebaseui from "firebaseui"

const useEmulator = import.meta.env.VITE_USE_EMULATOR === "true" || false
const lsConfig = localStorage.getItem("@askfounder/config")
const config = lsConfig ? JSON.parse(lsConfig) : {}
const trackingDisabled = config.ga_tracking === false
console.log({useEmulator, lsConfig, config, trackingDisabled})

// config
const firebaseConfig = {
  apiKey: import.meta.env.VITE_FIREBASE_API_KEY,
  authDomain: import.meta.env.VITE_FIREBASE_AUTH_DOMAIN,
  databaseURL: import.meta.env.VITE_FIREBASE_DATABASE_URL,
  projectId: import.meta.env.VITE_FIREBASE_PROJECT_ID,
  storageBucket: import.meta.env.VITE_FIREBASE_STORAGE_BUCKET,
  messagingSenderId: import.meta.env.VITE_FIREBASE_MESSAGING_SENDER_ID,
  appId: import.meta.env.VITE_FIREBASE_APP_ID,
  measurementId: import.meta.env.VITE_FIREBASE_MEASUREMENT_ID,
}

const firestoreConfig = {
  ignoreUndefinedProperties: true,
  experimentalAutoDetectLongPolling: true, // attempt to fix sentry/ADMIN-KD
  // experimentalForceLongPolling: true,
  merge: true,
}

// init
const app = !getApps().length ? initializeApp(firebaseConfig) : getApp()
const firestore = initializeFirestore(app, firestoreConfig)
const functions = getFunctions(app, "europe-west3")
const storage = getStorage(app)
const auth = getAuth(app)
const analytics =
  useEmulator || trackingDisabled
    ? false
    : (async () => {
        try {
          const isSupportedBrowser = await isSupportedAnalytics()
          if (isSupportedBrowser) {
            return getAnalytics(app)
          }
          console.log("Firebase analytics is not supported this browser")
          return null
        } catch (err) {
          console.log(err)
          return null
        }
      })()

// emulator
if (import.meta.env.VITE_ENVIRONMENT == "local_dev" && useEmulator) {
  console.log("using emulator: functions, firestore, storage, auth")
  connectFirestoreEmulator(firestore, "localhost", 4906)
  connectFunctionsEmulator(functions, "localhost", 4905)
  connectStorageEmulator(storage, "localhost", 4910)
  connectAuthEmulator(auth, "http://127.0.0.1:4904/", {
    disableWarnings: true,
  })
}

// export {firebaseui, firestore, functions, auth, storage, analytics}
export {firestore, functions, auth, storage, analytics, trackingDisabled}
