<script setup>
import SectionHero from "../components/sections/SectionHero.vue"
import SectionStats from "../components/sections/SectionStats.vue"
import SectionOffers from "../components/sections/SectionOffers.vue"
import SectionSpecialOffer from "../components/sections/SectionSpecialOffer.vue"
import SectionPartners from "../components/sections/SectionPartners.vue"
import SectionSupporters from "../components/sections/SectionSupporters.vue"
import SectionWhy from "../components/sections/SectionWhy.vue"
import SectionContribute from "../components/sections/SectionContribute.vue"
import SectionTeam from "../components/sections/SectionTeam.vue"
import SectionProjects from "../components/sections/SectionProjects.vue"
import SectionFooter from "../components/sections/SectionFooter.vue"
import {useLandingStore} from "../stores/landing"
import {storeToRefs} from "pinia"

defineProps({
  content: Object,
})

const landingStore = useLandingStore()

landingStore.bind()
</script>

<template>
  <div class="">
    <SectionHero
      class="min-h-[100svh] sm:min-h-0"
      :content="content.landing.sections.hero"
      :contentGlobal="content.global"
    />
    <SectionStats class="mt-20 md:mt-20" :content="content.landing.sections.stats" />
    <SectionSpecialOffer class="mt-20 md:mt-32" :content="content.landing.sections.specialOffer" />
    <SectionOffers class="mt-20 md:mt-32" :content="content.landing.sections.offers" />
    <SectionPartners class="mt-20 md:mt-32" :content="content.landing.sections.partners" />
    <SectionPartners class="mt-10 md:mt-10" :content="content.landing.sections.contributors" />
    <!--<SectionSupporters
      class="mt-20 md:mt-40"
      :content="content.landing.sections.supporters"
      :contentGlobal="content.global"
    />-->
    <SectionWhy class="md:mt-40" :content="content.landing.sections.why" />
    <SectionContribute
      class="md:mt-40"
      :content="content.landing.sections.contribute"
      :contentGlobal="content.global"
    />
    <SectionTeam class="md:mt-40" :content="content.landing.sections.team" />
    <SectionProjects class="mt-20 md:mt-32" :content="content.landing.sections.projects" />
    <SectionFooter class="" :content="content.landing.sections.footer" />
  </div>
</template>
