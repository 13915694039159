<script setup>
import {OneButton} from "@jirizavadil/framework.one/ui"
import sink from "@/sink/index.js"

const props = defineProps({
  view: String,
  content: Object,
  contentGlobal: Object,
})

const customContributionOption = props.contentGlobal.contributionOptions.find(
  (option) => option.custom
)

const production =
  import.meta.env.VITE_ENVIRONMENT === "production" && !location.href.includes("--preview")

console.log({customContributionOption})

function onClickContact() {
  const url = production
    ? customContributionOption.paymentUrl
    : customContributionOption.paymentUrlTest

  window.open(url, "_blank")

  sink.event("ContributeHeaderClick", {
    view: props.view,
  })
}
</script>

<template>
  <!-- top -->
  <div class="flex flex-row items-center justify-between pt-2 md:pt-10">
    <a class="text-2xl font-bold md:text-4xl" href="/">askfounder.ae</a>

    <!-- <OneButton
      :label="content.contributeLabel"
      kind="plain"
      :size="12"
      iconStart="Heart"
      @action="onClickContact()"
    /> -->
  </div>
</template>
