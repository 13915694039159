<script setup>
import LayoutContainer from "../layout/LayoutContainer.vue"
import {OneIcon} from "@jirizavadil/framework.one/ui"
import sink from "@/sink/index.js"

defineProps({
  content: Object,
})

function onClickProject(project) {
  sink.event("ProjectClick", {
    name: project.title,
  })
}
</script>

<template>
  <div class="bg-neutral-50 py-8 md:py-16 dark:bg-neutral-900">
    <LayoutContainer class="">
      <!-- title -->
      <div class="mt-10">
        <h3 class="text-4xl font-bold">{{ content.title }}</h3>
      </div>

      <!-- projects -->
      <div class="projects m-auto mt-10 pb-10">
        <div class="mt-4 grid grid-cols-1 gap-6 md:grid-cols-2">
          <a
            class="project group flex cursor-pointer flex-row items-end justify-between rounded-lg border border-neutral-200 p-4 dark:border-neutral-700"
            :href="project.href"
            target="_blank"
            v-for="project in content.list"
            :key="project.id"
            @click="onClickProject(project)"
          >
            <div>
              <h3 class="text-xl">{{ project.title }}</h3>
              <p class="text-base text-neutral-400">{{ project.description }}</p>
            </div>
            <OneIcon
              class="h-6 w-auto text-neutral-400 transition-all duration-300 ease-in-out group-hover:origin-bottom-left group-hover:scale-125"
              lucide="ArrowUpRight"
            />
          </a>
        </div>
      </div>
    </LayoutContainer>
  </div>
</template>
