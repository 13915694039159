const content = {
  en: {
    global: {
      contributionOptions: [
        {
          id: 1,
          value: 30,
          description: "Keep askfounder.ae free for others.",
          paymentUrlTest: "https://buy.stripe.com/test_3csdSC46QdlB97W3cd",
          paymentUrl: "https://buy.stripe.com/cN2cO82jV6CX3nidQV",
        },
        {
          id: 2,
          value: 80,
          description: "Support the project and its maintenance costs.",
          paymentUrlTest: "https://buy.stripe.com/test_7sIeWGcDmgxNac028a",
          paymentUrl: "https://buy.stripe.com/aEUeWg5w76CXga428c",
        },
        {
          id: 3,
          value: 200,
          description: "A thank you to the team to keep going.",
          paymentUrlTest: "https://buy.stripe.com/test_5kAdSC8n66Xdck8aEH",
          paymentUrl: "https://buy.stripe.com/14k01mbUv3qL6zu003",
        },
        {
          id: 4,
          value: "custom",
          description: "Contribute what you can, every dirham counts.",
          paymentUrlTest: "https://buy.stripe.com/test_8wMg0K9radlB5VK8wA",
          paymentUrl: "https://buy.stripe.com/fZe29ue2DaTdga43ce",
          custom: true,
        },
      ],
    },
    chat: {
      disclaimer: "Buddy can make mistakes. Consider checking important information.",
      suggestions: [
        {
          id: 1,
          question: "Do I have to pay taxes in the UAE?",
        },
        {
          id: 2,
          question: "What is the difference between a freezone and mainland company?",
        },
        {
          id: 3,
          question: "What are the best incubators for tech startups in the UAE?",
        },
        {
          id: 4,
          question: "Can I open business bank account on my own?",
        },
      ],
      contribution: {
        title: "Am I helpful?",
        description:
          "askfounder.ae is a crowdsourced initiative, donate to keep it free & accessible for you and others. Your contribution covers the expensive OpenAI bill.",
      },
    },
    chatNoFunds: {
      title: "Oh no!",
      paragraph: "We ran out of funds to run. But you can revive it & continue chatting instantly.",
    },
    chatThankYou: {
      title: "Oh yes!",
      paragraph: "Thank you for supporting UAE founders.",
      leaderboardForm: {
        title: "How do you wish to appear on the leaderboard?",
        labelAnonymous: "anonymous",
        labelEmail: "email",
        labelName: "name / company",
        labelNameInput: "name",
        placeholderNameInput: "Aisha Al-Mansoori",
        labelMessage: "message",
        placeholderMessage: "I love this!",
        labelUrl: "url",
        placeholderUrl: "linkedin.com/in/lala",
        labelSubmit: "save & start asking",
      },
    },
    landing: {
      sections: {
        hero: {
          contributeLabel: "contribute",
          title:
            'Independent & crowdsourced <span class="bg-success-400 px-2 font-bold dark:text-neutral-900">UAE&nbsp;founders\'&nbsp;AI&nbsp;buddy</span>',
          subtitle:
            "Everything you wish you knew before & while running a startup in the UAE. Community driven, free for all to use.",
          cta: "start asking",
          // cta2or: "or",
          // cta2: {
          //   label: "try a demo store",
          //   href: "https://demo.qasima.ae",
          // },
          bubbles: [
            {
              id: 1,
              question: "Do I have to pay taxes in the UAE?",
              topic: "corporate tax",
            },
            {
              id: 2,
              question: "Who are the most renowned investors in the UAE?",
              topic: "investors",
            },
            {
              id: 3,
              question: "What's the easiest business bank account to get?",
              topic: "banking",
            },
            {
              id: 4,
              question: "What do I have to do to close my freezone company?",
              topic: "closing a business",
            },
            {
              id: 5,
              question: "How can I get Golden Visa?",
              topic: "residency",
            },
            {
              id: 6,
              question: "What is the difference between a freezone and mainland company?",
              topic: "company set-up",
            },
            {
              id: 7,
              question: "Where can I network with other startup founders?",
              topic: "networking",
            },
            {
              id: 8,
              question: "What are the best incubators for tech startups in the UAE?",
              topic: "incubators",
            },
          ],
        },
        stats: {
          sourcesLabel: "knowledge sources added",
          questionsLabel: "questions answered to help UAE founders",
          contributedLabel: "AED contributed to support this project",
        },
        specialOffer: {
          title: "Welcome to Dubai\nFull package to start your business",
          // url: "",
          // logo: "",
          // logoOnDark: "",
          description:
            "While askfounder.ae bot is free, you might need more details and structure to get ready for starting & growing your business in Dubai.\n\nThis full package contains our own custom made, secret guides and resources that will save you over $30 000 and help you step by step to setup your company, get your visa, bank account, apartment, contacts on major investors and more.\nAll with skipping the middle man and getting the knowledge you need to grow your Dubai business. ",
          // caption:"* share your name, email, phone number over email, we'll connect you with the Finanshels team within 24 hrs",
          highlighted: true,
          highlightedLabel: "$30 000 IN BENEFITS",
          cta: {
            label: "save $30k now",
            href: "https://shop.melsova.com/en/all-products/product-detail/full-package-start-your-dubai-business",
          },
        },
        offers: {
          title: "Perks for our community",
          list: [
            // {
            //   id: 1,
            //   slug: "meydan-setup",
            //   title: "700 AED OFF your business set up",
            //   url: "https://meydanfz.ae",
            //   logo: "/img/partners/offer-partner-meydan.svg",
            //   logoOnDark: "/img/partners/offer-partner-meydan-ondark.svg",
            //   description:
            //     "Get referred directly to Meydan by askfounder.ae & enjoy 700 AED off and VIP support",
            //   caption:
            //     "* share your name, email, phone number over email, we'll connect you with the Meydan team within 24 hrs\n** your name must not already be registered at Meydan to claim the offer",
            //   cta: {
            //     label: "claim offer",
            //     href: "mailto:kristina@melsova.com?subject=askfounder.ae%20perk:%20meydan",
            //   },
            // },
            // {
            //   id: 2,
            //   slug: "askbot-ai-chatbot",
            //   title: "10% OFF your own AI bot",
            //   url: "https://askbot.ae/",
            //   logo: "/img/partners/offer-partner-askbot.svg",
            //   logoOnDark: "/img/partners/offer-partner-askbot-ondark.svg",
            //   description:
            //     "Like askfounder? Use our platform for your own AI bot, trained on your knowledge base. No development needed.",
            //   cta: {
            //     label: "claim offer",
            //     href: "mailto:kristina@melsova.com?subject=askfounder.ae%20perk:%20askbot",
            //   },
            // },
            {
              id: 3,
              slug: "finanshels-accounting-annual-basic",
              title: "Annual basic accounting for 4000 AED",
              url: "https://finanshels.com/",
              logo: "/img/partners/offer-partner-finanshels.svg",
              logoOnDark: "/img/partners/offer-partner-finanshels-ondark.svg",
              description:
                "Get referred directly to Finanshels & let them take care of your accounting & fillings (up to 200 transactions/Q)",
              caption:
                "* share your name, email, phone number over email, we'll connect you with the Finanshels team within 24 hrs",
              cta: {
                label: "claim offer",
                href: "mailto:kristina@melsova.com?subject=askfounder.ae%20perk:%20finanshels%20accounting%20annual%20basic",
              },
            },
            {
              id: 4,
              slug: "finanshels-accounting-services",
              title: "5% OFF accounting services",
              url: "https://finanshels.com/",
              logo: "/img/partners/offer-partner-finanshels.svg",
              logoOnDark: "/img/partners/offer-partner-finanshels-ondark.svg",
              description:
                "Get referred directly to Finanshels by askfounder.ae & enjoy 5% off their accounting services",
              caption:
                "* share your name, email, phone number over email, we'll connect you with the Finanshels team within 24 hrs",
              cta: {
                label: "claim offer",
                href: "mailto:kristina@melsova.com?subject=askfounder.ae%20perk:%20finanshels%20accounting%20services",
              },
            },
            {
              id: 5,
              slug: "humlog-hr-services",
              title: "10% OFF people solution services ",
              url: "https://humloghr.com/",
              logo: "/img/partners/offer-partner-humlog.svg",
              logoOnDark: "/img/partners/offer-partner-humlog-ondark.svg",
              description:
                "Get referred directly to Humlog by askfounder.ae & enjoy 10% off their HR services",
              caption:
                "* share your name, email, phone number over email, we'll connect you with the Humlog team within 24 hrs",
              cta: {
                label: "claim offer",
                href: "mailto:kristina@melsova.com?subject=askfounder.ae%20perk:%20humlog%20hr%20services",
              },
            },
          ],
        },
        partners: {
          title: "sponsors",
          list: [
            {
              id: 1,
              logo: "/img/partners/partner-start-oops-and-ups-of-dubai.svg",
              logoOnDark: "/img/partners/partner-start-oops-and-ups-of-dubai-ondark.svg",
              class: "w-32 md:w-40",
              url: "https://startoopsandupsofdubai.substack.com/",
            },
            // {
            //   id: 2,
            //   logo: "/img/partners/partner-24six9.svg",
            //   logoOnDark: "/img/partners/partner-24six9-ondark.svg",
            //   class: "w-20 md:w-24 -mt-1",
            //   url: "https://24six9.com",
            // },
            // {
            //   id: 3,
            //   logo: "/img/partners/partner-bioplastica.svg",
            //   class: "w-28 md:w-36 pt-1",
            //   url: "",
            // },
            // {
            //   id: 4,
            //   logo: "/img/partners/partner-sedlackova.svg",
            //   class: "w-28 md:w-36",
            //   url: "",
            // },
          ],
          cta: {
            label: "does your too?",
            href: "mailto:kristina@melsova.com",
          },
        },
        contributors: {
          title: "knowledge contributors",
          list: [
            {
              id: 1,
              logo: "/img/partners/contributor-jesani-advisory.png",
              logoOnDark: "/img/partners/contributor-jesani-advisory-ondark.png",
              class: "w-32 md:w-40",
              url: "https://www.jesaniadvisory.com",
            },
            {
              id: 2,
              logo: "/img/partners/partner-24six9.svg",
              logoOnDark: "/img/partners/partner-24six9-ondark.svg",
              class: "w-20 md:w-24 -mt-1",
              url: "https://24six9.com",
            },
            {
              id: 3,
              logo: "/img/partners/contributor-logo-melsova-com.svg",
              logoOnDark: "/img/partners/contributor-logo-melsova-com-ondark.svg",
              class: "w-32 md:w-36",
              url: "https://melsova.com",
            },
            {
              id: 4,
              logo: "/img/partners/contributor-logo-finanshels.svg",
              logoOnDark: "/img/partners/contributor-logo-finanshels-ondark.svg",
              class: "w-28 md:w-32",
              url: "https://finanshels.com",
            },
            {
              id: 5,
              logo: "/img/partners/offer-partner-humlog.svg",
              logoOnDark: "/img/partners/offer-partner-humlog-ondark.svg",
              class: "w-20 md:w-24 -mt-1",
              url: "https://humloghr.com",
            },
          ],
          cta: {
            label: "does your too?",
            href: "mailto:kristina@melsova.com",
          },
        },
        supporters: {
          top: {
            title: "Top supporters",
            seeAllLabel: "see all",
          },
          join: {
            title: "Join the heroes",
            description:
              "askfounder.ae is a crowdsourced initiative, contribute to keep it free & accessible for you and others. Your contribution covers the expensive Open AI bill.",
          },
        },
        why: {
          title: "Why?",
          paragraph:
            "We're Kristina and George, two bootstrapped founders based in Dubai, who struggled to find accurate and free information to set up and run our businesses in the UAE. If you're here, you probably know what we're talking about.\nSo, this GPT is the buddy we wish we had earlier, that can help us make the startup life a bit easier and cheaper. It's a crowdsourced community project.",
        },
        contribute: {
          title: "Want to contribute?",
          list: [
            {
              id: 1,
              title: "add knowledge",
              description:
                "Help us teach the buddy relevant topics for UAE founders. Are you a lawyer, accountant, mentor, investor or have other useful knowledge? Drop us an e-mail with text and links that cover the knowhow.",
              cta: {
                label: "email Kristina",
                href: "mailto:kristina@melsova.com",
              },
            },
            {
              id: 2,
              title: "keep it free",
              description:
                "OpenAI & engineering bills aren't free. We spend our time and resources to make this project happen. Contribute to keep the buddy free for you & other founders.",
              cta: {
                label: "sponsor",
                href: "mailto:kristina@melsova.com",
              },
            },
          ],
        },
        team: {
          title: "Who's behind this",
          list: [
            {
              id: "kristina",
              image: "/img/team/kristina_melsova.jpg",
              name: "Kristina Melsova",
              description:
                "Bootstrapped Founder 🇦🇪 UNWTO startup winner🥇travel | creator economy | e-commerce 📬Start Oops & Ups of Dubai newsletter",
              cta: {
                label: "melsova.com",
                href: "https://melsova.com",
              },
            },
            {
              id: "george",
              image: "/img/team/jiri_zavadil.png",
              name: "Jiri (George) Zavadil",
              description: "ecommerce • branding • ui/ux",
              cta: {
                label: "zavadil.pro",
                href: "https://zavadil.pro",
              },
            },
          ],
        },
        projects: {
          title: "Other projects",
          list: [
            {
              id: "qasima",
              title: "qasima",
              description: "digital gift cards for your customers",
              href: "https://qasima.ae",
            },
            {
              id: "atlaseek",
              title: "atlaseek",
              description: "uniting creators, explorers & unique destinations",
              href: "https://atlaseek.com",
            },
            {
              id: "start-oops-and-ups-of-dubai",
              title: "start oops & ups of Dubai",
              description: "Weekly stories about founder successes & fails",
              href: "https://startoopsandupsofdubai.substack.com",
            },
            {
              id: "platforma-one",
              title: "platforma.one",
              description: "eshops that sell more",
              href: "https://platforma.one",
            },
          ],
        },
        footer: {
          company: "PLGRND LLC-FZ",
          termsLabel: "terms",
          author: "made in 🇦🇪",
          links: [
            // {id: 1, title: "email", url: "mailto:jiri@zavadil.pro"},
            // {id: 2, title: "x.com", url: "https://x.com/jirizavadil"},
            // {id: 1, title: "instagram", url: "https://instagram.com/qasima.ae", icon: "Instagram"},
            // {id: 4, title: "facebook", url: "https://facebook.com/jirizavadil"},
            {id: 5, title: "linkedin", url: "https://www.linkedin.com/company/askfounder-ae"},
            {id: 6, title: "x", url: "https://x.com/askfounder_ae"},
            // {id: 6, title: "github", url: "https://github.com/jirizavadil"},
            // {id: 7, title: "dribbble", url: "https://dribbble.com/jirizavadil"},
          ],
        },
      },
    },
  },
}

const base = {
  locales: [
    {
      id: "en",
      title: "english",
    },
  ],
  content,
}

export default base
