import {ref} from "vue"
import {defineStore, acceptHMRUpdate} from "pinia"
import {firestore} from "@/firebase/index.js"
import {doc} from "firebase/firestore"

export const useLandingStore = defineStore("landing", () => {
  // state
  const landing = ref({})
  const status = ref("init")
  const unsubscribe = ref(null)

  // getters

  // actions
  async function bind() {
    const docRef = doc(firestore, `app/landing`)
    return await this.attach("landing", docRef)
  }

  async function unbind() {
    console.log("unbind")
    return await this.detach()
  }

  return {
    // state
    landing,
    status,
    unsubscribe,

    // getters

    // actions
    bind,
    unbind,
  }
})

if (import.meta.hot) {
  import.meta.hot.accept(acceptHMRUpdate(useLandingStore, import.meta.hot))
}
